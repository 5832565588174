<template>
  <div>
    <gro-card class="mb-5">
      <div slot="body">
        <gro-breadcrum>
          <gro-breadcrum-item
              class="circular-std-bold"
              :to="{name: 'portal.dashboard'}"
              aria-label="Title"
              title="Dashboard">
          </gro-breadcrum-item>
          <gro-breadcrum-item
              :to="{name: 'portal.referrals'}"
              aria-label="Title"
              icon="arrow_right"
              material
              title="Referrals">
          </gro-breadcrum-item>
          <gro-breadcrum-item
              icon="arrow_right"
              material
              :title="patient.name">
          </gro-breadcrum-item>
        </gro-breadcrum>
      </div>
    </gro-card>
      <div class="container mt-5" style="font-family: 'Circular Std Book', Arial;" ref="content">
        <div v-if="isLoadingPatient" class="spinner__wrap">
          <gro-spinner size="lg" color="blue"></gro-spinner>
        </div>
        <div v-if="!isLoadingPatient">
          <div class="d-flex justify-content-between mb-3">
            <h1 class="mb-2">Patient detail</h1>
            <div class="d-flex align-items-center">
              <gro-button class="mr-2" @click="openUpdatePanel">
                <i class="icon icon-pencil-edit mr-1" style="vertical-align: middle;"></i>
                Update details
              </gro-button>
              <gro-button class="mr-2" loading-title="Exporting data..." :loading="isLoadingExport" :disabled="isLoadingExport" @click="generateReport()">
                <i  class="icon material-icons mr-1" style="vertical-align: middle;">
                  <svg width="21" height="21" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_418_901" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                      <path d="M20 0H0V20H20V0Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_418_901)">
                      <path d="M16.2222 17.5999H3.77777C3.56947 17.5999 3.39999 17.4205 3.39999 17.1999C3.39999 16.0138 3.40144 14.3421 3.4052 13.5999H3.41011V13.1999C3.41011 13.1535 3.41095 13.1013 3.42095 13.0704L4.97651 8.27041C5.02205 8.12957 5.13644 8.02907 5.2715 8.0053C5.34064 8.11399 5.41709 8.21108 5.50231 8.29873L5.9922 8.7999H5.89411H5.60327L5.51359 9.07657L4.21704 13.0766L4.04741 13.5999H4.59755H15.4032H15.9534L15.7837 13.0766L14.4872 9.07657L14.3975 8.7999H14.1067H14.0081L14.497 8.29941C14.5819 8.21209 14.6584 8.1148 14.7281 8.00522C14.8633 8.02885 14.9779 8.12939 15.0234 8.27014L16.579 13.0704C16.589 13.1013 16.5899 13.1534 16.5899 13.1999V13.5999H16.5948C16.5985 14.3421 16.6 16.0138 16.6 17.1999C16.6 17.4205 16.4305 17.5999 16.2222 17.5999ZM9.99999 10.6276L6.62505 7.17471C6.47467 7.02003 6.47467 6.75737 6.62579 6.60193C6.69794 6.52812 6.78917 6.4887 6.88888 6.4887C6.9886 6.4887 7.07983 6.52812 7.15272 6.60269L8.93542 8.43633L9.62222 9.14276V8.1575V2.7999C9.62222 2.57934 9.79169 2.3999 9.99999 2.3999C10.2083 2.3999 10.3778 2.57934 10.3778 2.7999V8.1575V9.14276L11.0646 8.43633L12.848 6.60193C12.9131 6.53533 13.0093 6.4967 13.1111 6.4967C13.2129 6.4967 13.3091 6.53533 13.3749 6.60269C13.5255 6.75758 13.5264 7.01933 13.3761 7.1735L9.99999 10.6276Z" fill="#062940"/>
                      <path d="M3.80368 14C3.80106 14.8518 3.8 16.2326 3.8 17.2H16.2C16.2 16.2326 16.1989 14.8518 16.1963 14H3.80368ZM10 2C10.4293 2 10.7778 2.3584 10.7778 2.8V8.1576L12.5612 6.3232C12.8552 6.02239 13.367 6.02241 13.661 6.3232C13.9643 6.6352 13.9643 7.1432 13.6626 7.4528L10 11.2L6.339 7.4544C6.03567 7.1424 6.03567 6.6352 6.339 6.3232C6.486 6.1728 6.68122 6.0888 6.88889 6.0888C7.09656 6.0888 7.29178 6.1728 7.43878 6.3232L9.22222 8.1576V2.8C9.22222 2.3584 9.57067 2 10 2ZM5.33334 7.6H5.50366C5.576 7.7504 5.667 7.8944 5.78911 8.02L6.94256 9.2H5.89411L4.59756 13.2H15.4032L14.1067 9.2H13.0582L14.2109 8.02C14.333 7.8944 14.4232 7.7504 14.4963 7.6H14.6667C15.0019 7.6 15.2982 7.82 15.404 8.1472L16.9596 12.9472C16.9868 13.0312 16.9899 13.116 16.9899 13.2C17 13.2 17 17.2 17 17.2C17 17.6424 16.6523 18 16.2222 18H3.77778C3.34766 18 3 17.6424 3 17.2C3 17.2 3 13.2 3.01011 13.2C3.01011 13.116 3.01322 13.0312 3.04045 12.9472L4.596 8.1472C4.70178 7.82 4.99811 7.6 5.33334 7.6Z" fill="#062940"/>
                    </g>
                  </svg>
                </i>
                Export data
              </gro-button>
              <gro-button v-if="isSuperUser" class="mr-2 delete-button" @click="openDeletePanel">
                <i class="icon icon-trash mr-1" style="vertical-align: middle;"></i>
                Delete
              </gro-button>
             <div>
              <unit-preferences></unit-preferences>
             </div>
            </div>
          </div>

          <div class="duplicate mb-3" :class="{'multiple': patient.duplicateCount > 1}" v-if="isSuperUser && (patient.isDuplicate || patient.duplicateCount > 1)">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex">
                <div class="duplicate_icon" :class="{'multiple': patient.duplicateCount > 1}">
                  <i class="icon material-icons mr-2">info</i>
                </div>
                <span style="vertical-align: super">
                  <strong>Please note:</strong> {{patient.duplicateCount > 1 ? `this entry has ${patient.duplicateCount} duplicates` : 'this entry is a duplicate'}}
                </span>
              </div>
              <div v-if="patient.duplicateOfLeadId">
                <span @click="goToMainEntry(patient.duplicateOfLeadId)" style="cursor:pointer;" class="d-flex text-underline">Go to main entry</span>
              </div>
            </div>
          </div>


          <gro-card class="mb-3">
            <div slot="body">

              <div class="row">
                <div class="col-9">
                  <h2>{{ patient.name }}</h2>
                  <div class="mb-2" style="font-family: 'Circular Std Book', Arial;">User {{ patient.id }} · {{ patient.email }}</div>

                  <div class="mb-2 patient-details d-flex flex-wrap">
                    <div class="patient-detaoils-item pl-0">
                      <span>Post code</span>
                      <div class="d-flex">
                        <span class="postcode">{{ patient.location ? patient.location : 'N/A' }}</span>
                        <img v-if="patient.targetPostcode" class="ml-2" src="@/assets/flagged_pc.svg"/>
                      </div>
                    </div>
                    <div class="patient-details-item">
                      <span>Height</span>
                      <div>{{height}}</div>
                    </div>
                    <div class="patient-details-item">
                      <span>Weight</span>
                      <div>{{weight}}</div>
                    </div>
                    <div class="patient-details-item">
                      <span>Age</span>
                      <div>{{ patient.age ? patient.age : 'N/A' }} years</div>
                    </div>
                    <div class="patient-details-item">
                      <span>Sex</span>
                      <div>{{ patient.gender ? patient.gender : 'N/A' }}</div>
                    </div>
                    <div class="patient-details-item ">
                      <span>Application date</span>
                      <div>{{ patient.applicationDate }}</div>
                    </div>
                    <div style="width:100%;"></div>
                    <div class="patient-details-item pl-0">
                      <span>Ethnicity</span>
                      <div>{{ patient.ethnicity ? patient.ethnicity.label : 'N/A' }}</div>
                    </div>
                    <div class="patient-details-item">
                      <span>Phone number</span>
                      <div>{{ patient.telephone ? patient.telephone : 'N/A' }}</div>
                    </div>
                    <div class="patient-details-item">
                      <span>GP surgery</span>
                      <div>{{ patient.gp ? patient.gp.name : 'N/A' }}</div>
                    </div>
                    <div class="patient-details-item">
                      <span>Signed up to Gro</span>
                      <div>{{ patient.groAccountCreated ? 'Yes' : 'No' }}</div>
                    </div>
                    <div class="patient-details-item" v-if="patient.postNatal">
                      <span>Is Postnatal?</span>
                      <div>{{ patient.postNatal !== null ? patient.postNatal : 'N/A' }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="bmi relative">
                    <round-slider
                      line-cap="round"
                      :showTooltip="false"
                      circle-shape="half-top"
                      v-model="patient.bmi"
                      :class="bmiColourClass"
                      min="10"
                      max="55"
                      :read-only="true"
                    ></round-slider>
                    <div class="bmi-chart">
                      <div class="bmi-chart-number">{{patient.bmi.toFixed(1)}}</div>
                      <div class="bmi-chart-label mt-3">Body Mass Index</div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </gro-card>

          <gro-card class="mb-3 session_attendance">
            <div slot="body">
              <h3 class="mb-3">Session attendance</h3>
              <p class="mb-3">Log attendance of one-to-one coaching sessions and/or face-to-face sessions</p>
              <div>
                <table  class="gro__table w-100" style="overflow: hidden;background: #FFF;box-shadow: 0 0 0 1px #ebebf0;">
                  <thead style="background: #fff;height: 60px;">
                    <tr>
                      <td class="circular-std-bold pl-3">Week</td>
                      <td class="circular-std-bold pl-custom-1">1</td>
                      <td class="circular-std-bold pl-custom-1">2</td>
                      <td class="circular-std-bold pl-custom-1">3</td>
                      <td class="circular-std-bold pl-custom-1">4</td>
                      <td class="circular-std-bold pl-custom-1">5</td>
                      <td class="circular-std-bold pl-custom-1">6</td>
                      <td class="circular-std-bold pl-custom-1">7</td>
                      <td class="circular-std-bold pl-custom-1">8</td>
                      <td class="circular-std-bold pl-custom-1">9</td>
                      <td class="circular-std-bold pl-custom-2">10</td>
                      <td class="circular-std-bold pl-custom-2">11</td>
                      <td class="circular-std-bold pl-custom-2">12</td>
                      <td class="circular-std-bold pl-custom-2">26</td>
                      <td class="circular-std-bold pl-custom-2">52</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="circular-std-bold pl-3">
                        Status
                      </td>
                      <td v-for="(item,index) in attendanceList" :key="index">
                        <div>
                          <gro-popper :config="{placement: 'top',  modifiers: { preventOverflow: { enabled: true,padding: 20},offset: {offset: '0, 16', }}}" style="left: 16px; top: 16px">
                            <gro-outside-click slot-scope="{isOpen, open, close, update}" :do="close">
                              <div>
                                <div data-trigger @click="update" class="d-inline">
                                  <div class="d-flex">
                                    <gro-fab-button :disabled="isUpdatingWeek.bool && isUpdatingWeek.week === item.week" @click.native="open" :class="{'attended': item.status === 'Attended', 'not-attended': item.status === 'DidNotAttend'}">
                                      <gro-spinner v-if="isUpdatingWeek.bool && isUpdatingWeek.week === item.week"  style="height:30px;width:30px;" size="sm" color="blue"></gro-spinner>
                                      <div v-else>
                                        <i v-if="item.status === 'Attended'" class="material-icons" style="vertical-align: middle;">check</i>
                                        <i v-if="item.status === 'DidNotAttend'" class="material-icons" style="vertical-align: middle;">close</i>
                                        <i v-if="item.status === '' || item.status === 'NoStatus'" class="material-icons" style="vertical-align: middle;">question_mark</i>
                                      </div>
                                    </gro-fab-button>
                                  </div>
                                </div>
                                <div v-show="isOpen" data-popper style="z-index: 999;">
                                  <gro-card class="options-card">
                                    <div slot="body">
                                      <div class="options_list">
                                        <div class="option" @click="updateWeek(item, close, 'Attended')">Attended</div>
                                        <div class="option" @click="updateWeek(item, close, 'DidNotAttend')">Did not attend</div>
                                        <div class="option" @click="updateWeek(item, close, 'NoStatus')">No status</div>
                                      </div>
                                    </div>
                                  </gro-card>
                                </div>
                              </div>
                            </gro-outside-click>
                          </gro-popper>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </gro-card>

          <gro-card class="mb-3 weight_monitoring">
            <div slot="body">
              <h3 class="mb-3">Weight monitoring</h3>
              <p class="mb-3">Add weight readings for this individual. These readings will not show up in Gro Health app.</p>
              <table  class="gro__table w-100 mb-3" style="overflow: hidden;background: #FFF;box-shadow: 0 0 0 1px #ebebf0;">
                <thead style="background: #fff;height: 40px;">
                  <tr>
                    <td class="circular-std-bold pl-custom-1">Date</td>
                    <td class="circular-std-bold pl-custom-1">Time point</td>
                    <td class="circular-std-bold pl-custom-1">Weight reading (st, lbs)</td>
                    <td class="circular-std-bold pl-custom-1">Weight reading (kg)</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in patient.weights" :key="`item-${index}`">
                    <td class="pl-custom-1">{{ item.recordedAt === null ? 'Date not available' : item.recordedAt }}</td>
                    <td class="pl-custom-1">Week {{item.weekNumber}}</td>
                    <td class="pl-custom-1">{{kgToStonesAndPounds(item.weightKg)}}</td>
                    <td class="pl-custom-1">{{item.weightKg}} kg</td>
                  </tr>
                </tbody>
              </table>

              <gro-button
                  class="mb-2 w-100"
                  style="position: relative; z-index: 0"
                  type="button"
                  size="md"
                  gradient
                  glowing
                  centered
              @click="openWeightModal()">
                <i class="icon icon-add-circle mr-1" style="vertical-align: middle;"></i>
                Add Weight
              </gro-button>
            </div>
          </gro-card>


          <gro-card class="mb-3 conditions_and_meds">
            <div slot="body">
              <h3 class="mb-3">Health conditions & medications</h3>
              <div class="row mb-4">
                <div class="col-6">
                  <gro-card>
                    <div slot="body">
                      <h4 class="smaller-title mb-2">Health conditions</h4>
                      <div v-for="(item, index) in patient.conditions" :key="`item-${index}`">
                        <p>{{item.name}}</p>
                      </div>
                      <div v-if="patient.conditions.length === 0" class="font-size-14" style="color: #706f6f;">No conditions available</div>
                    </div>
                  </gro-card>
                </div>

                <div class="col-6">
                  <gro-card>
                    <div slot="body">
                      <h4 class="smaller-title mb-2">Medications</h4>
                      <div v-for="(item, index) in patient.medications" :key="`item-${index}`">
                        <p>{{item.name}}</p>
                      </div>
                      <div v-if="patient.medications.length === 0" class="font-size-14" style="color: #706f6f;">No medications available</div>
                    </div>
                  </gro-card>
                </div>

              </div>
              <div class="row">

                <div class="col-12 alcohol-info">
                  <gro-card>
                    <div slot="body">
                      <h4 class="smaller-title mb-2">Alcohol intake</h4>
                      <div class="row">
                        <div class="col-6">
                          <div class="mb-2">
                            <p class="alcohol-label circular-std-bold">Drinks Alcohol?</p>
                            <p>{{patient.drinkAlcohol ? 'Yes' : 'No'}}</p>
                          </div>
                          <div class="mb-2">
                            <p class="alcohol-label circular-std-bold">Frequency?</p>
                            <p>{{patient.alcoholFrequency !== null ? patient.alcoholFrequency.label : 'N/A'}}</p>
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="mb-2">
                            <p class="alcohol-label circular-std-bold">Alcoholic drinks consumed on a typical day</p>
                            <p>{{patient.dailyCountAlcohol !== null ? patient.dailyCountAlcohol.label : 'N/A'}}</p>
                          </div>
                          <div class="mb-2">
                            <p class="alcohol-label circular-std-bold">Frequency of consuming 6+ alcoholic drinks on one occasion</p>
                            <p>{{patient.overSixCountAlcohol !== null ? patient.overSixCountAlcohol.label : 'N/A'}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </gro-card>
                </div>

              </div>
            </div>
          </gro-card>


          <gro-card class="mb-3 referral-management">
            <div slot="body">
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="mb-3">Referral management</h3>
                <div class="d-flex duplicate_button" v-if="isSuperUser" v-tooltip="{content: 'This user is a main entry', show: patient.duplicateCount > 0}" :class="{'disabled': patient.isDuplicate === true || patient.duplicateCount > 0}" @click="markLeadAsDuplicate()">
                  <div class="duplicate_icon" :class="{'multiple': patient.duplicateCount > 1}">
                    <i class="icon material-icons mr-2">info</i>
                  </div>
                  <span style="vertical-align: super">
                    {{this.patient.isDuplicate ? 'Marked as duplicate' : 'Mark as duplicate'}}
                  </span>
                </div>
              </div>
              <form @submit.prevent="onSubmitReferral">

                <div class="gro__input mb-2">
                  <label class="gro__input__label">Eligibility status
                    </label>
                  <gro-radio v-model="model.eligibility" label="Eligible" :value="true"
                             :input-value="true"></gro-radio>
                  <gro-radio v-model="model.eligibility" class="ml-3" :value="false" label="Not eligible"
                             :input-value="false"></gro-radio>
                </div>

                <div class="gro__input mb-2">
                  <label class="gro__input__label">Referral source</label>
                  <a v-if="patient.source !== 'Referral system'" :href="`https://www.grohealth.com/${patient.source.replace('Gro Health Landing - ', '').toLowerCase()}`">{{ patient.source }}</a>
                  <span v-else>{{patient.source}}</span>

                  <span v-if="patient.referrerDetails">- {{patient.referrerDetails}}</span>

                </div>

                <div class="gro__select gro__input mb-3">
                  <label for="subSource" class="gro__input__label">Sub source?</label>
                  <select id="subSource" v-model="model.subSource"
                          class="gro__select__native__element gro__input__element">
                    <option :value="null" label="Select one" selected></option>
                    <option v-for="item, index in subReferralSources" :key="`item-${index}`" :label="item.name" :value="item.id">{{item.name}}</option>
                  </select>
                </div>

                <div class="gro__input mb-2">
                  <label class="gro__input__label">Contact status
                    </label>
                  <gro-radio v-model="model.status" label="Not contacted" value="NotContacted"
                             :input-value="'NotContacted'"></gro-radio>
                  <gro-radio v-model="model.status" class="ml-3" label="Contacted" value="Contacted"
                             :input-value="'Contacted'"></gro-radio>
                  <gro-radio v-model="model.status" class="ml-3" value="NoResponse" label="Did not respond"
                             :input-value="'NoResponse'"></gro-radio>
                </div>

                <gro-input
                    v-show="model.status === 'Contacted' || model.status === 'NoResponse'"
                    class="mb-2"
                    required
                    id="txt_contactNote"
                    name="txt_contactNote"
                    label="Contact notes"
                    type="text"
                    placeholder="State why you contacted this member (e.g. 26 Week Follow-Up)"
                    v-model="model.contactNote"
                    :is-error="$v.model.contactNote.$error"
                />

                <div class="gro__input mb-2">
                  <label class="gro__input__label">Preferred method of delivery
                    </label>
                  <gro-radio v-model="model.preferredContact" label="Digital" value="Digital"
                             :input-value="'Digital'"></gro-radio>
                  <gro-radio v-model="model.preferredContact" class="ml-3" label="Face-to-face" value="FacetoFace"
                             :input-value="'FacetoFace'"></gro-radio>
                  <gro-radio v-model="model.preferredContact" class="ml-3" label="Digital + Face-to-face" value="DigitalFaceToFace"
                             :input-value="'DigitalFaceToFace'"></gro-radio>
                  <gro-radio v-model="model.preferredContact" class="ml-3" value="Offline" label="Offline"
                             :input-value="'Offline'"></gro-radio>
                </div>


                <gro-card class="delivery-address mb-3" v-if="model.preferredContact === 'Offline'">
                  <div slot="body">
                    <h4 class="mb-2">Offline pack delivery address</h4>
                    <gro-input
                        class="mb-2"
                        id="txt_addressline1"
                        name="txt_addressline1"
                        label="Address line 1"
                        type="text"
                        placeholder="Address line 1"
                        v-model="model.deliveryAddress.addressLine1"
                        :is-error="$v.model.deliveryAddress.addressLine1.$error"
                    />
                    <gro-input
                        class="mb-2"
                        id="txt_addressline2"
                        name="txt_addressline2"
                        label="Address line 2"
                        type="text"
                        placeholder="Address line 1"
                        v-model="model.deliveryAddress.addressLine2"
                        :is-error="$v.model.deliveryAddress.addressLine2.$error"
                    />

                    <div class="row mb-1">
                      <div class="col">
                        <gro-input
                            label="Town/city"
                            id="txt_townCity"
                            name="txt_townCity"
                            type="text"
                            placeholder="Town/city"
                            v-model="model.deliveryAddress.townCity"
                            :is-error="$v.model.deliveryAddress.townCity.$error">
                        </gro-input>
                      </div>
                      <div class="col">
                        <gro-input
                            label="Postcode"
                            id="txt_postcode"
                            name="txt_postcode"
                            type="text"
                            placeholder="Postcode"
                            v-model="model.deliveryAddress.postcode"
                            :is-error="$v.model.deliveryAddress.postcode.$error">
                        </gro-input>
                      </div>
                    </div>
                  </div>
                </gro-card>

                <div class="mb-2">
                  <div class="gro__input">
                    <label class="gro__input__label mb-2" >
                      Preferred weight management service
                    </label>
                  </div>
                  <div class="d-flex flex-wrap flex-row" style="max-width: 500px;">
                    <div v-for="(item, index) in referralServicesList" :key="`goal-${index}`" class="mb-2 mr-3">
                      <gro-checkbox :disabled="(item.name === 'Maidstone Leisure Centre' && model.preferredContact === 'Digital')"  v-model="item.checked" :value="item.value"
                                    :label="item.name"></gro-checkbox>
                    </div>
                  </div>
                </div>

                <div class="mb-2">
                  <div class="gro__input">
                    <label class="gro__input__label mb-2">
                      Local services requested
                    </label>
                  </div>
                  <div class="d-flex flex-wrap flex-column" style="height: 110px;width: 500px;">
                    <div v-for="(item, index) in services" :key="`goal-${index}`" class="mb-2">
                      <gro-checkbox v-model="item.checked" :value="item.value"
                                    :label="item.name"></gro-checkbox>
                    </div>
                  </div>
                </div>

                <div class="mb-2">
                  <div class="gro__input">
                    <label class="gro__input__label mb-2">
                      What are the applicant’s health improvement goals?
                    </label>
                  </div>
                  <div class="d-flex flex-wrap flex-column" style="height: 110px;width: 500px;">
                    <div v-for="(item, index) in goalsList" :key="`goal-${index}`" class="mb-2">
                      <gro-checkbox v-model="item.checked" :value="item.value"
                                    :label="item.name"></gro-checkbox>
                    </div>
                  </div>
                </div>

                <div class="gro__input mb-2">
                  <label class="gro__input__label">Intervention completion status <i class="icon-info-circle" v-tooltip="{content: 'This field indicates whether the intervention was completed by the applicant.'}"></i></label>
                  <gro-radio v-model="model.completionStatus" label="Not complete" value="NotComplete"
                             input-value="NotComplete"></gro-radio>
                  <gro-radio v-model="model.completionStatus" class="ml-3" value="Complete" label="Completed"
                             input-value="Complete"></gro-radio>
                </div>


                <div class="gro__input mb-2">
                  <label class="gro__input__label">Referral status <i class="icon-info-circle" v-tooltip="{content: 'This field indicates the status of the referral. Use this to track who you’ve finished referring or discharged from a given intervention.'}"></i></label>
                  <gro-radio v-model="model.referralCompletionStatus" label="Not complete" value="NotComplete"
                             input-value="NotComplete"></gro-radio>
                  <gro-radio v-model="model.referralCompletionStatus" class="ml-3" value="Complete" label="Completed"
                             input-value="Complete"></gro-radio>
                  <gro-radio v-model="model.referralCompletionStatus" class="ml-3" value="Discharged" label="Discharged"
                             input-value="Discharged"></gro-radio>
                </div>

                <gro-input
                    v-show="model.referralCompletionStatus === 'Discharged'"
                    class="mb-3"
                    required
                    id="txt_dischargedNote"
                    name="txt_dischargedNote"
                    label="Discharge notes"
                    type="text"
                    placeholder="State why this member was discharged"
                    v-model="model.dischargeNote"
                    :is-error="$v.model.dischargeNote.$error"
                />



                <div class="gro__input mb-2">
                  <label class="gro__input__label">Is Postnatal?</label>
                  <gro-radio v-model="model.postNatal" label="Yes" :value="true"
                             :input-value="true"></gro-radio>
                  <gro-radio v-model="model.postNatal" class="ml-3" :value="false" label="No"
                             :input-value="false"></gro-radio>
                </div>


                <div class="gro__input mb-2">
                  <label class="gro__input__label">Requires support with learning difficulties or disabilities</label>
                  <gro-radio v-model="model.requireDisabilitySupport" label="Yes" :value="true"
                             :input-value="true"></gro-radio>
                  <gro-radio v-model="model.requireDisabilitySupport" class="ml-3" :value="false" label="No"
                             :input-value="false"></gro-radio>
                </div>

                <div class="mb-3">
                  <div class="gro__input">
                    <label class="gro__input__label">
                      Has Gro account?
                    </label>
                  </div>
                  <div class="d-flex">
                    <div class="d-inline-flex">
                      <gro-radio v-model="model.groAccountCreated" :value="true" label="Yes"
                      ></gro-radio>
                    </div>
                    <div class="d-inline-flex pl-3">
                      <gro-radio v-model="model.groAccountCreated" :value="false" label="No"
                      ></gro-radio>
                    </div>
                  </div>
                </div>

<!--                <gro-inline-date-of-birth-->
<!--                    v-show="model.groAccountCreated"-->
<!--                    class="mb-3"-->
<!--                    label="Gro account creation date"-->
<!--                    required-->
<!--                    v-model="model.groAccountCreatedAt"-->
<!--                />-->

                <gro-button
                    class="mb-2"
                    :loading="isProcessing"
                    :disabled="isProcessing"
                    loading-title="Processing..."
                    style="position: relative; z-index: 0"
                    type="submit"
                    size="md"
                    gradient
                    glowing
                    centered>
                  Save changes
                  <i class="icon-right-arrow relative font-size-16"></i>
                </gro-button>

              </form>
            </div>
          </gro-card>

          <div class="row history-and-notes mt-4">
            <div class="col-6">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h3>History</h3>
                <div>
                  <gro-popper :config="{placement: 'right-start',  modifiers: {
            preventOverflow: {
              enabled: true,
              padding: 20
            },
            offset: {
              offset: '0, 16',
            }
          }}" style="left: 16px; top: 16px">
                    <gro-outside-click slot-scope="{isOpen, open, close, update}" :do="close">
                      <div>
                        <div data-trigger @click="update" class="d-inline">
                          <div class="d-flex">
                            <gro-button @click.native="open">
                              <i class="icon icon-calendar mr-1" style="vertical-align: middle;"></i>
                              Date range
                            </gro-button>
                          </div>
                        </div>
                        <div v-show="isOpen" data-popper class="gro__calendar__popper" style="z-index: 999;">
                          <gro-card>
                            <div slot="body">
                              <div class="d-flex">
                                <div class="mr-4">
                                  <div class="mb-2">From date</div>
                                  <gro-single-date-picker
                                      @input="loadHistory"
                                      :maxDate="dateTo"
                                      v-model="dateFrom">
                                  </gro-single-date-picker>
                                </div>
                                <div>
                                  <div class="mb-2">To date</div>
                                  <gro-single-date-picker
                                      @input="loadHistory"
                                      :maxDate="todaysDate"
                                      v-model="dateTo">
                                  </gro-single-date-picker>
                                </div>
                              </div>
                            </div>
                          </gro-card>
                        </div>
                      </div>
                    </gro-outside-click>
                  </gro-popper>
                </div>
              </div>
              <gro-card>
                <div slot="body">
                  <div class="history">
                    <div v-for="(timeline,index) in history" :key="`item-${index}`" style="font-family: 'Circular Std Book', Arial;">
                      {{formatHistoryDateSection(timeline.createdAt)}}
                      <div class="history-item-wrap mt-3 mb-3">
                        <div class="d-flex">
                          <div :class="{'green-dot': index != Object.keys(history).length - 1}" class="blue-dot">
                            ●
                          </div>
                          <div class="ml-2">
                            <span v-if="index != Object.keys(history).length - 1">Changes saved by {{timeline.usernameAuthor}}</span>
                            <span v-else>New application</span>
                          </div>
                        </div>
                        <div class="history-item mt-2 mb-2" v-for="(item,index) in timeline.messageLog" :key="`item-${index}`">
                          <span>{{item.label}}: <span class="note-msg">{{formatItem(item.body, item.label)}}</span></span>
                        </div>
                        <span class="history-item-date">
                      {{formatHistoryDateItem(timeline.createdAt)}}
                    </span>
                      </div>
                    </div>
                  </div>
                </div>
              </gro-card>
            </div>
            <div class="col-6">
              <h3 class="mb-3">Notes</h3>
              <form @submit.prevent="updateNote">
                <gro-card>
                  <div slot="body">
                    <gro-textarea
                        class="mb-3 text-area"
                        required
                        id="txt_note"
                        name="txt_note"
                        label="Note"
                        type="tel"
                        placeholder="Add a note"
                        v-model="note"
                        rows="8"
                    ></gro-textarea>

                    <gro-button
                        class="mb-1"
                        :loading="isProcessingNote"
                        loading-title="Processing..."
                        style="position: relative; z-index: 0"
                        type="submit"
                        size="md"
                        gradient
                        glowing
                        centered>
                      Save changes
                      <i class="icon-right-arrow relative font-size-16"></i>
                    </gro-button>
                  </div>
                </gro-card>
              </form>
            </div>
          </div>
        </div>
      </div>
      <gro-modal scrollable :is-open="isUpdatingPatient" width="640px" @close="confirmCloseUpdate">
        <gro-card>
          <div slot="body">
            <update-patient
                :patient="patient"
                :ethnicities="ethnicities"
                :alcoholFrequencyList="alcoholFrequencyList"
                :dailyAlcoholList="dailyAlcoholList"
                :services="services"
                :goalsList="goalsList"
                :referralServicesList="referralServicesList"
                @success="getPatient"
                @close="confirmCloseUpdate">
            </update-patient>
          </div>
        </gro-card>
      </gro-modal>
      <gro-modal :is-open="isDeletingPatient" height="auto" @close="isDeletingPatient = false">
        <gro-card>
          <div slot="body">
            <h2 class="mb-3">Confirm deletion</h2>
            <p class="mb-3">Once delete, you will not be able to recover this entry.</p>
            <div class="d-flex align-items-center justify-content-end">
              <gro-button @click="isDeletingPatient = false">Cancel</gro-button>
              <gro-button fill fill-color="#ff394a" class="ml-2 delete_confirm" @click="deleteLead">Delete</gro-button>
            </div>
          </div>
        </gro-card>
      </gro-modal>
      <gro-modal :is-open="isAddingWeight" height="auto" @close="isAddingWeight = false">
      <gro-card>
        <div slot="body">
         <add-weight-reading
             @success="getPatient" :patient="patient" @close="isAddingWeight = false"></add-weight-reading>
        </div>
      </gro-card>
    </gro-modal>
  </div>
</template>

<script>
import {referralClient} from "@/helpers/referralClient";
import moment from "moment";
import {validationMixin} from 'vuelidate'
import {required, requiredIf} from "vuelidate/lib/validators";

import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import RoundSlider from 'vue-round-slider'
import store from "@/store";
import UpdatePatient from '@/views/Portal/_components/UpdatePatient'
import UnitPreferences from '@/views/Portal/_components/UnitPreferences'
import AddWeightReading from '@/views/Portal/_components/AddWeightReading'

export default {
  components: {RoundSlider, UpdatePatient, UnitPreferences, AddWeightReading},
  mixins: [validationMixin],
  validations: {
    model: {
      deliveryAddress: {
        addressLine1: {
          required: requiredIf(function (form) {
            return this.model.preferredContact === 'Offline'
          }),
        },
        addressLine2: {
          required: requiredIf(function (form) {
            return this.model.preferredContact === 'Offline'
          }),
        },
        townCity: {
          required: requiredIf(function (form) {
            return this.model.preferredContact === 'Offline'
          }),
        },
        postcode: {
          required: requiredIf(function (form) {
            return this.model.preferredContact === 'Offline'
          }),
        }
      },
      contactNote: {
        required: requiredIf(function (form) {
          return form.status === 'Contacted'
        })
      },
      dischargeNote: {
        required: requiredIf(function (form) {
          return form.referralCompletionStatus === 'Discharged'
        })
      },
    }
  },
  watch: {
    'model.preferredContact': {
      handler(value) {
        if (value === 'Digital') {
          this.referralServicesList
              .find(x => (x.name === 'Maidstone Leisure Centre')).checked = false;
        }
      }
    },
    referralServicesList: {
      handler() {
        this.model.referredServices = this.referralServicesList
            .filter(x => x.checked === true)
            .map(x => (x.id))
      },
      deep: true,
    },
    goalsList: {
      handler() {
        this.model.goals = this.goalsList
            .filter(x => x.checked === true)
            .map(x => ({
              name: x.name,
              goalId: x.goalId
            }))
      },
      deep: true
    },
    services: {
      handler() {
        this.model.services = this.services
            .filter(x => x.checked === true)
            .map(x => (x.id))
      },
      deep: true
    },
  },
  async mounted() {
    this.isLoadingPatient = true;
    let { id } = this.$route.params;
    const patient = await referralClient.get(`/Lead/${id}`);
    await this.getUserHistory(patient.data.id);
    const referralServices = await referralClient.get('/Reference/referralservices');
    const servicesData = await referralClient.get('/Reference/services');
    let services = servicesData.data.map(obj => ({...obj, checked: false}))
    this.services = services
    const goals = await referralClient.get('/Reference/goals')
    let newGoals = goals.data.map(obj => ({...obj, checked: false}))
    let newReferralServices = referralServices.data.map(obj => ({...obj, checked: false}));
    this.goalsList = newGoals;

    const referralSources =  await referralClient.get('/reference/sources');
    if (referralSources.data) {
      this.subReferralSources = referralSources.data
          .filter(item => item.subSources.length > 0)
          .map(item => item.subSources)
          .flat();
    }

    const alcoholFreqOptions = await referralClient.get('/Reference/alcoholfrequency');
    const alcoholDailyCount = await referralClient.get('/Reference/dailycountalcohol');


    this.alcoholFrequencyList = alcoholFreqOptions.data;
    this.dailyAlcoholList = alcoholDailyCount.data;


    this.patient = patient.data;
    this.referralServicesList = newReferralServices;

    if (patient.data.referredServices) {
      for (const [key, value] of Object.entries(patient.data.referredServices)) {
        this.referralServicesList.forEach((item) => {
          if (item.name === value) {
            item.checked = true;
          }
        })
      }
    }

    if (patient.data.servicesRequested) {
      for (const [key, value] of Object.entries(patient.data.servicesRequested)) {
        this.services.forEach((item) => {
          if (item.name === value) {
            item.checked = true;
          }
        })
      }
    }

    if (patient.data.goals) {
      patient.data.goals.forEach((patientGoal) => {
        this.goalsList.forEach((item) => {
          if (item.name === patientGoal.name) {
            item.checked = true;
          }
        })
      })
    }

    //Setup user form data
    if(patient.data.status) {
      this.model.status = patient.data.status.enumValue;
    }

    if (patient.data.preferredContact) {
      this.model.preferredContact = patient.data.preferredContact.enumValue;
    }

    if (patient.data.referralCompletionStatus) {
      this.model.referralCompletionStatus = patient.data.referralCompletionStatus.enumValue;
    }

    if (patient.data.eligible != null) {
      this.model.eligibility = patient.data.eligible;
    }
    if (patient.data.preferredContact) {
      this.model.preferredContact = patient.data.preferredContact.enumValue;
    }
    if (patient.data.completed) {
      this.model.completionStatus = patient.data.completed.enumValue;
    }

    if (patient.data.deliveryAddress !== null) {
      this.model.deliveryAddress.addressLine1 = patient.data.deliveryAddress.addressLine1
      this.model.deliveryAddress.addressLine2 = patient.data.deliveryAddress.addressLine2
      this.model.deliveryAddress.townCity = patient.data.deliveryAddress.townCity
      this.model.deliveryAddress.postcode = patient.data.deliveryAddress.postcode
    }

    if (patient.data.dischargeNote) {
      this.model.dischargeNote = patient.data.dischargeNote;
    }

    if(patient.data.requireDisabilitySupport !== null) {
      this.model.requireDisabilitySupport = patient.data.requireDisabilitySupport;
    }

    if(patient.data.postNatal !== null) {
      this.model.postNatal = patient.data.postNatal;
    }

    if (patient.data.groAccountCreated !== null) {
      this.model.groAccountCreated = patient.data.groAccountCreated;
     // this.model.groAccountCreatedAt = patient.data.groAccountCreatedAt;
    }

    if (patient.data.isDuplicate) {
      this.duplicateStatus = patient.data.isDuplicate;
    }

    if (patient.data.subSource) {
      this.subSourceText = patient.data.subSource;

      const foundItem = this.subReferralSources.find(item => item.name === this.subSourceText);
      const id = foundItem ? foundItem.id : null;
      this.model.subSource = id;
    }

    await this.updateSessions();

    this.isLoadingPatient = false;
  },
  data() {
    return {
      patient: null,
      model: {
        eligibility: null,
        status: null,
        contactNote: null,
        referredServices: null,
        preferredContact: null,
        completionStatus: null,
        goals: [],
        services: null,
        referralCompletionStatus: null,
        dischargeNote: null,
        requireDisabilitySupport: null,
        deliveryAddress: {
          addressLine1: null,
          addressLine2: null,
          postcode: null,
          townCity: null
        },
        groAccountCreated: null,
        groAccountCreatedAt: "",
        postNatal: null,
        subSource: null,
      },
      isProcessing: false,
      note: null,
      history: null,
      referralServicesList: null,
      isProcessingNote: false,
      dateFrom: moment('01-01-2023', 'DD-MM-YYYY').toDate(),
      dateTo:  moment().toDate(),
      goalsList: null,
      isLoadingPatient: false,
      unitPreferences: {
        height: 'kg',
        weight: 'ftin',
      },
      isUpdatingPatient: false,
      services: null,
      todaysDate: moment().toDate(),
      isLoadingExport: false,
      alcoholFrequencyList: null,
      dailyAlcoholList: null,
      attendanceList: [
        {
          week: 1,
          status: '',
        },
        {
          week: 2,
          status: '',
        },
        {
          week: 3,
          status: '',
        },
        {
          week: 4,
          status: '',
        },
        {
          week: 5,
          status: '',
        },
        {
          week: 6,
          status: '',
        },
        {
          week: 7,
          status: '',
        },
        {
          week: 8,
          status: '',
        },
        {
          week: 9,
          status: '',
        },
        {
          week: 10,
          status: '',
        },
        {
          week: 11,
          status: '',
        },
        {
          week: 12,
          status: '',
        },
        {
          week: 26,
          status: '',
        },
        {
          week: 52,
          status: '',
        },
      ],
      isUpdatingWeek: false,
      isDeletingPatient: false,
      isAddingWeight: false,
      subReferralSources: null,
      subSourceText: null,
    }
  },
  methods: {
    goToMainEntry(id) {
      this.$router.push({
        name: 'portal.referrals.id',
        params: {
          id: id
        }
      })
    },
    kgToStonesAndPounds(kg) {
      const KG_TO_STONE = 0.157473; // 1 kg = 0.157473 stones
      const KG_TO_POUND = 2.20462;  // 1 kg = 2.20462 lbs

      let totalStones = kg * KG_TO_STONE;
      let stones = Math.floor(totalStones); // Get the whole number of stones
      let remainingKg = kg - (stones / KG_TO_STONE); // Get the remaining kg
      let pounds = remainingKg * KG_TO_POUND; // Convert remaining kg to pounds

      return `${stones} st ${pounds.toFixed(1)} lbs`;
    },
    async updateSessions() {
      let { id } = this.$route.params;
      const attendance = await referralClient.get(`/Lead/attendance/${id}`)

      this.attendanceList.forEach((item) => {
        attendance.data.value.forEach((week) => {
          if (item.week === week.week) {
            item.status = week.status
          }
        })
      })
    },
    async updateWeek(item, close, attended) {
      let { id } = this.$route.params;
      this.isUpdatingWeek = {
        week: item.week,
        bool: true,
      };
      try {
        const updateAttendance = await referralClient.put(`/Lead/attendance/${id}`, {
          week: item.week,
          status: attended
        })
        close();
        await this.updateSessions();
      } catch(e) {
        console.log(e)
      }
      this.isUpdatingWeek = {
        week: item.week,
        bool: false,
      }
    },
    markLeadAsDuplicate() {
      let { id } = this.$route.params;
      if (this.patient.isDuplicate) {
        return;
      }
      referralClient.put(`Lead/duplicatestatus/${id}`,  {isDuplicate: true})
          .then((res) => {
            console.log(res)
            this.$toast.success('User marked as duplicate')
            this.getPatient(true);
          })
          .catch((ex) => {
            console.log(ex);
          })
    },
    deleteLead() {
      let { id } = this.$route.params;
      referralClient.delete(`Lead/${id}`)
        .then((res) => {
          console.log(res)
          this.$toast.success('User deleted')
          this.$router.push({
            name: 'portal.referrals',
          });
        })
        .catch((ex) => {
          console.log(ex);
        })
    },
    formatItem(item, label){
      // let string = label.toLowerCase();
      // if (string.includes('weight') && parseInt(item)) {
      //   let num = parseInt(item);
      //   if (num > 150) {
      //     return item / 1000 + 'kg';
      //   } else {
      //     return item + 'kg';
      //   }
      // } else if (string.includes('height') && parseInt(item)) {
      //   return item / 1000 + 'cm';
      // } else {
      //   return item;
      // }
      return item
    },
    async getPatient(val) {
      if (val === true) {
        let { id } = this.$route.params;
        const patient = await referralClient.get(`/Lead/${id}`);
        this.patient = patient.data;

        if (patient.data.referredServices) {
          for (const [key, value] of Object.entries(patient.data.referredServices)) {
            this.referralServicesList.forEach((item) => {
              if (item.name === value) {
                item.checked = true;
              }
            })
          }
        }

        if (patient.data.servicesRequested) {
          for (const [key, value] of Object.entries(patient.data.servicesRequested)) {
            this.services.forEach((item) => {
              if (item.name === value) {
                item.checked = true;
              }
            })
          }
        }

        if (patient.data.goals) {
          patient.data.goals.forEach((patientGoal) => {
            this.goalsList.forEach((item) => {
              if (item.name === patientGoal.name) {
                item.checked = true;
              }
            })
          })
        }

        //Setup user form data
        if(patient.data.status) {
          this.model.status = patient.data.status.enumValue;
        }

        if (patient.data.preferredContact) {
          this.model.preferredContact = patient.data.preferredContact.enumValue;
        }

        if (patient.data.referralCompletionStatus) {
          this.model.referralCompletionStatus = patient.data.referralCompletionStatus.enumValue;
        }

        if (patient.data.eligible != null) {
          this.model.eligibility = patient.data.eligible;
        }
        if (patient.data.preferredContact) {
          this.model.preferredContact = patient.data.preferredContact.enumValue;
        }
        if (patient.data.completed) {
          this.model.completionStatus = patient.data.completed.enumValue;
        }

        if (patient.data.deliveryAddress !== null) {
          this.model.deliveryAddress.addressLine1 = patient.data.deliveryAddress.addressLine1
          this.model.deliveryAddress.addressLine2 = patient.data.deliveryAddress.addressLine2
          this.model.deliveryAddress.townCity = patient.data.deliveryAddress.townCity
          this.model.deliveryAddress.postcode = patient.data.deliveryAddress.postcode
        }

        if (patient.data.dischargeNote) {
          this.model.dischargeNote = patient.data.dischargeNote;
        }

        if(patient.data.requireDisabilitySupport !== null) {
          this.model.requireDisabilitySupport = patient.data.requireDisabilitySupport;
        }
        this.isUpdatingPatient = false;
        this.isAddingWeight = false;
        await this.getUserHistory(id);
      }

    },
    async getUserHistory(patientId) {

      this.dateFrom = new Date(new Date(this.dateFrom).setHours(0,0,0,0))

      if (this.dateTo !== moment().toDate()) {
        this.dateTo = new Date(new Date(this.dateTo).setHours(23,59,59,999));
      }

      let params = {
        leadId: patientId,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      }
      const history = await referralClient.get('/Lead/history', { params })
      this.history = history.data.filter(x => x.messageLog.length > 0);
    },
    async onSubmitReferral() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }



      this.isProcessing = true;

      //let groCreatedDate = this.model.groAccountCreated === true ?  moment(this.model.groAccountCreatedAt).format() : null;

      let model = {
        leadId: this.patient.id,
        status: this.model.status,
        preferredContact: this.model.preferredContact,
        referredServices: this.model.referredServices,
        contactNote: this.model.contactNote,
        services: this.model.services,
        goals: this.model.goals,
        referralCompletionStatus: this.model.referralCompletionStatus,
        dischargeNote: this.model.dischargeNote,
        deliveryAddress: {
          addressLine1: this.model.deliveryAddress.addressLine1,
          addressLine2: this.model.deliveryAddress.addressLine2,
          townCity: this.model.deliveryAddress.townCity,
          postcode: this.model.deliveryAddress.addressLine1,
        },
        requireDisabilitySupport: this.model.requireDisabilitySupport,
        groAccountCreated: this.model.groAccountCreated,
        postNatal: this.model.postNatal,
        subSourceId: this.model.subSource
      };

      if (this.model.deliveryAddress.addressLine1 === null || this.model.deliveryAddress.addressLine1 === "") {
        delete model.deliveryAddress;
      }

      try {
       await referralClient.put('/Lead/referral', model)
          .then((res) => {
            this.$toast.success('User updated')
          })

          setTimeout(async () => {
            let eligible = this.model.eligibility;
            await referralClient.put(`/Lead/eligible/${this.patient.id}?request=${eligible}`)
                .then(() => {
                  this.$toast.success('Eligibility updated')
                })
          }, 1000);

          if (this.model.completionStatus) {
            setTimeout(async () => {
              await referralClient.put(`/Lead/completed/${this.patient.id}?request=${this.model.completionStatus}`)
                  .then(() => {
                    this.$toast.success('Completion status updated')
                  })
            }, 1000);
          }
      } finally {
        this.isProcessing = false;
        let { id } = this.$route.params;
        await this.getUserHistory(id);
        const patient = await referralClient.get(`/Lead/${id}`);
        this.patient = patient.data;
        this.model.contactNote = '';
      }

    },
    async updateNote() {
      if (this.note === '') {
        return;
      }

      this.isProcessingNote = true

      let model = {
        leadId: this.patient.id,
        note: this.note
      };

     await referralClient.post('/Lead/note', model)
          .then(async (res) => {
            await this.getUserHistory(this.patient.id);
            this.isProcessingNote = false;
          });

     this.note = '';
    },
    formatHistoryDateSection(date) {
      return moment(date).format('MMMM YYYY');
    },
    formatHistoryDateItem(date) {
      return moment(date).format('DD/MM/YYYY HH:mm a');
    },
    formatGroDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    generateReport() {
      this.isLoadingExport = true;
      const pdfConfig = {
        jsPDFOptions: {
          orientation: 'portrait',
          size: 'a4',
          unit: 'px',
          hotfixes: ['px_scaling'],
        },
        html2canvasOptions: {
          scale: 2,
          dpi: 300,
        },
        imageOptions: {
          contentType: 'image/png',
          imageType: 'PNG',
          leftRightMargin: 0,
          topBottomMargin: 0
        }
      };

      const {jsPDFOptions, html2canvasOptions, imageOptions} = pdfConfig;

      html2canvas(this.$refs.content, html2canvasOptions)
          .then((canvas: HTMLCanvasElement) => {
            // const imageData = canvas.toDataURL(imageOptions.imageType);
            // const doc = new jsPDF(jsPDFOptions);
            // const {pageSize} = doc.internal;
            // const width = pageSize.getWidth();
            // const height = pageSize.getHeight();
            //
            // const imgProps= doc.getImageProperties(imageData);
            // const pdfHeight = (imgProps.height * width) / imgProps.width;
            //
            //
            // doc.addImage(
            //     imageData,
            //     imageOptions.imageType,
            //     imageOptions.leftRightMargin,
            //     imageOptions.topBottomMargin,
            //     width,
            //     pdfHeight
            // );
            var imgData = canvas.toDataURL('image/png');
            var imgWidth = 210;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            var doc = new jsPDF('p', 'mm');
            var position = 0;

            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
              position = heightLeft - imgHeight;
              doc.addPage();
              doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
            }
            //doc.save( 'file.pdf');


            let formattedTime = moment().format('DD-MM-YYYY hh:mm:ss a');

            doc.save(`Gro-ReferralData-${formattedTime}.pdf`);
            this.isLoadingExport = false;
          })
    },
    async loadHistory() {
     await this.getUserHistory(this.patient.id)
    },
    openDeletePanel() {
      setTimeout(() => {
        this.isDeletingPatient = true;
      }, 50);
    },
    openWeightModal() {
      setTimeout(() => {
        this.isAddingWeight = true;
      }, 50);
    },
    openUpdatePanel() {
      setTimeout(() => {
        this.isUpdatingPatient = true;
      }, 50);
    },
    confirmCloseUpdate() {
      this.$deleteAccept({
        title: "About to close update panel.",
        confirmMessageTemplate: "Are you sure?",
        confirmButtonLabel: "Yes",
        cancelButtonLabel: "No",
        animation: {
          confirmAnimationLeaveClass: 'zoomOut',
          confirmAnimationEnterClass: 'zoomIn'
        }
      })
          .then(() => {
            this.isUpdatingPatient = false;
          })
          .catch(() => {
          })
    },
  },
  computed: {
    ethnicities() {
      return this.$store.getters['User/getEthnicities'];
    },
    formatDate() {
      return moment(this.patient.applicationDate).format('DD/MM/YYYY');
    },
    bmiColourClass () {
      let roundedBmi = this.patient.bmi.toFixed(1)
      if (roundedBmi < 18.5 ) {
        return 'yellow-bmi'
      } else if (roundedBmi >= 18.5 && roundedBmi <= 24.9) {
        return 'green-bmi'
      } else if (roundedBmi >= 25) {
        return 'red-bmi'
      } else {
        return '';
      }
    },
    weight () {
      if (this.patient.heightCm) {
        if (store.getters['User/getUserPreferences'].weight === 'kg') {
          return (this.patient.weightGrams / 1000).toFixed(1) + 'kg';
        } else if (store.getters['User/getUserPreferences'].weight === 'stlbs') {
          let stlbs = this.patient.weightGrams * 0.00015747;
          return Math.floor(stlbs) + "st " + (14 * (stlbs - Math.floor(stlbs))).toFixed(0) + 'lbs';
        } else {return ''}
      } else {
        return 'N/A'
      }
    },
    height() {
      if (this.patient.weightGrams) {
        if (store.getters['User/getUserPreferences'].height === 'ftin') {
          let ftin = this.patient.heightCm * 0.032808;
          return Math.floor(ftin) + "ft " + (12 * (ftin - Math.floor(ftin))).toFixed(0) + 'in';
        } else if (store.getters['User/getUserPreferences'].height === 'cm'){
          return this.patient.heightCm + ' cm';
        } else { return ''}
      } else {
        return 'N/A'
      }
    },
    isSuperUser() {
      let claims = store.getters['Auth/getClaims'];
      return ['super'].some(x => claims.role.includes(x));
    }
  },
}
</script>

<style lang="scss">
@import "~@gro-dev/gro-component-kit-for-web/src/theme/mixins/gradients";
.scrollable { height: 90%!important; display: block!important; }
  .container {
    .icon-info-circle {
      color: #c8c7cc;
      font-size: 12px;
    }
    //PDF Fixes
    label, span, .gro__button__text, .patient-details, .gro__radio__label, .gro__checkbox__label {
      font-family: 'Circular Std Book', Arial;
    }
    .spinner__wrap {
      text-align: center;
      .gro__spinner {
        color: #0278f8;
      }
    }
    .gro__button {
      &:hover {
        svg {
          path {
            fill: #FFF;
          }
        }
      }
    }
    .bmi {
      padding: 24.5px 22.9px 13.9px 21.8px;
      border-radius: 12px;
      border: solid 1px #ede7fb;
      background-color: #fff;
      .rs-range {
        stroke: none!important;
      }
      .red-bmi {
        .rs-path {
          stroke: #ea3131!important;
        }
      }
      .yellow-bmi {
        .rs-path {
          stroke: #ffd643!important;
        }
      }
      .green-bmi {
        .rs-path {
          stroke: #30e52c!important;
        }
      }
      .bmi-chart {
        margin: 3.3px 0 2.8px;
        padding: 23.7px 0 19.4px;
        text-align: center;
        position: absolute;
        position: absolute;
        bottom: -8px;
        left: 67px;
        .bmi-chart-number {
          font-size: 22px;
          font-weight: 500;
          color: #062940;
          font-family: 'Circular Std Book', Arial;
        }
        .bmi-chart-label {
          font-size: 12.4px;
          text-align: center;
          color: #707070;
          text-transform: uppercase;
          font-family: 'Circular Std Book', Arial;
        }
      }
    }
    .duplicate {
      border-radius: 12px;
      border: solid 1px #fc9202;
      background-color: rgba(252, 146, 2, 0.1);
      padding: 12px 16px 9px;
      &.multiple {
        border: solid 1px #0078f8;
        background-color: rgba(0, 120, 248, 0.1);
      }
      .duplicate_icon {
        color: #fc9202;
        &.multiple {
          background: -moz-linear-gradient(top, #0078f8 0%, #b134ff 100%);
          background: -webkit-linear-gradient(top, #0078f8 0%, #b134ff 100%);
          background: linear-gradient(to bottom, #0078f8 0%, #b134ff 100%);
          -webkit-background-clip: text;
          -moz-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      span {
        font-size: 1rem;
      }
    }
    .duplicate_button {
      cursor: pointer;
      padding: 7px 15px 0px;
      border-radius: 9999px;
      border: solid 1px #fc9202;
      background-color: rgba(252, 146, 2, 0.1);
      &.disabled {
        cursor: not-allowed;
        opacity: .7;
      }
      .duplicate_icon {
        color: #fc9202;
        font-size: 16px;
      }
    }
    .patient-details {
      .patient-details-item {
        padding: 5px 22px;
        border-right: 1px solid #c8c7cc;
        .postcode {
          text-transform: uppercase;
          font-size: 16px;
          color: #062940;
        }
        &.no-border {
          border: none;
        }
        span {
          font-size: 12px;
          text-transform: uppercase;
          color: #707070;
        }
      }
    }
    .delivery-address {
      background: #fafafa;
      h4 {
        font-size: 14px;
      }
    }
    .delete-button {
      i, .gro__button__text {
        color: #ff394a;
      }
    }

    .session_attendance {
      table {
        box-shadow: 0 1.3px 5px 0 rgba(0, 0, 0, 0.19), 0 0.3px 1.5px 0 rgba(0, 0, 0, 0.04);
        td {
          padding: 14px 8px;
          &.pl-custom-1 {
            padding-left: 22px;
          }
          &.pl-custom-2 {
            padding-left: 19px;
          }
        }
      }
      .gro__fab__button {
        box-shadow: none;
        i {font-size: 1.1rem;}
        border: 1px dashed #c8c7cc;
        &.attended {
          @include generateLinearGradient();
          border:none;
          i {
            color: #fff;
          }
        }
        &.not-attended {
          background: #ff394a!important;
          border:none;
          i {
            color: #fff;
          }
        }
      }
      .options-card .gro__card__body{padding:8px;}
      .options_list {
        .option {
          margin-bottom: 5px;
          padding: 5px;
          cursor: pointer;
          text-align: center;
          border-radius: 8px;
          &:hover {
            background:#e1e1e4;
          }
        }
      }
    }

    .weight_monitoring {
      table {
        box-shadow: 0 1.3px 5px 0 rgba(0, 0, 0, 0.19), 0 0.3px 1.5px 0 rgba(0, 0, 0, 0.04);
        td {
          padding: 14px 8px;
          &.pl-custom-1 {
            padding-left: 22px;
          }
          &.pl-custom-2 {
            padding-left: 19px;
          }
        }
      }
    }

    .conditions_and_meds {
      .smaller-title {
        color: #707070;
        font-size: 12px;
        text-transform: uppercase;
      }
      .alcohol-info {
        .alcohol-label {
          font-size: 14px;
        }
      }
    }
    .history-and-notes {
      .history {
        .history-item-wrap {
          padding: 8px 12px;
          border-radius: 12px;
          box-shadow: 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.19), 0 0 1px 0 rgba(0, 0, 0, 0.04);
          .green-dot {
            color: #4cd964!important;
          }
          .blue-dot {
            color: #0078f8;
          }
          .history-item {
            background: #FFFCFC;
            padding: 8px 12px;
            border-radius: 12px;
            box-shadow: 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.19), 0 0 1px 0 rgba(0, 0, 0, 0.04);
            font-size: 14px;
            .note-msg {
              font-weight: bold;
            }
          }
          .history-item-date {
            font-size: 14px;
            color: #7a7a7a;
          }
        }
      }
      textarea {
        height: 150px;
      }
    }
  }

.gro__button.delete_confirm:not(.gradient):hover:not([disabled]) {
  &:hover {
    background: #ff394a!important;
  }
}
</style>